// See .cache/page-templates after running dev/build
// to understand how this file ends up looking

import React, {useEffect} from "react"
import Img from 'gatsby-image';
import styled from 'styled-components';
import "../../src/sass/pages/_blog-single.scss";
import Seo from '../../src/components/seo'
// import '../sass/pages/components/'
import { graphql } from 'gatsby'

import {SchemaLinksToAbsolute} from '../../src/../src/helpers/SchemaLinksToAbsolute'

import BasicTextBlock from '../../src/components/page/BasicTextBlock'

const ContainsCustomColours = styled.div`

.bg-triangle .bg{
  background: -webkit-linear-gradient(right, ${(props) => props.customColour1}, ${(props) => props.customColour2}) !important;
}

.label.cat{
  background: -webkit-linear-gradient(right, ${(props) => props.customColour1}, ${(props) => props.customColour2}) !important;
-webkit-background-clip: text !important;
-webkit-text-fill-color: transparent !important;
}

`

const PageTemplate = pageProps => {
  let data = pageProps.data;
  const seoTitle = pageProps.data.wpPage.seo.title
  const seoDesc = pageProps.data.wpPage.seo.metaDesc
data = pageProps.data.wpPage
  const componentsArray = data.acf_pageBuilder.pageBuilder || []
  const components = componentsArray.map(component => {
    return {
      name: component.__typename.split('_').pop(),
      data: component,
    }
  })
  // let colourSetter = {
  //   color1: null, 
  //   color2: null, 
  //   headerColor: 'null',
  // }
  // if(pageProps.pageContext.slug === 'search-engine-optimisation'){
  //   colourSetter.color1 = '#EE6B4B';
  //   colourSetter.color2 = '#fd9c16';
  //   colourSetter.headerColor = 'black';
  // }
  // if(pageProps.pageContext.slug === 'conversion-rate-optimisation'){
  //   colourSetter.color1 = '#36E8F1';
  //   colourSetter.color2 = '#4F19FF';
  //   colourSetter.headerColor = 'black';
  // }
  // if(pageProps.pageContext.slug === 'pay-per-click'){
  //   colourSetter.color1 = '#CE58D1';
  //   colourSetter.color2 = '#ED8DF4';
  //   colourSetter.headerColor = 'black';
  // }
  // if(pageProps.pageContext.slug === 'copywriting'){
  //   colourSetter.color1 = '#FF3838';
  //   colourSetter.color2 = '#FF7A7A';
  //   colourSetter.headerColor = 'black';
  // }
  // if(pageProps.pageContext.slug === 'content-marketing'){
  //   colourSetter.color1 = '#299143';
  //   colourSetter.color2 = '#4DE745';
  //   colourSetter.headerColor = 'black';
  // }

useEffect(() => {
  if(typeof document !== 'undefined'){
    document.querySelector('body').setAttribute('data-bg-color', 'bg-white')
    document.querySelector('body').setAttribute('data-class', 'flex-single')
  }
}, [])

useEffect(() => {
  if(typeof window !== 'undefined'){
    const scroller = document.querySelector('#container-scroller')
    const anchors = Array.from(document.querySelectorAll('[href^="#"]'))
    const header = document.querySelector('#header')
    const hh = header.offsetHeight
    
    if(anchors.length > 0){
      anchors.map(anchor => {
        anchor.addEventListener('click', function(e){
          e.preventDefault()
          console.log('clicked')
          const id = anchor.hash;
          const yourElement = document.querySelector(id);
          const y = yourElement.getBoundingClientRect().top + window.pageYOffset - hh - 35;
          scroller.scrollTo({top: y, behavior: 'smooth'});
        })
      })
    }
  }
})

  return (
    <div 
    className='outer-component-wrapper' 
    data-color1={pageProps.pageContext.color1} 
    data-color2={pageProps.pageContext.color2} 
    // data-headerColor={colourSetter.headerColor} 
    data-slug={pageProps.pageContext.slug}
    >
      <Seo 
      title={seoTitle} 
      description={seoDesc} 
      date={pageProps.pageContext.date}
      slug={'/'+pageProps.pageContext.slug+'/'}
      robotsFollow={pageProps.data.wpPage.seo.metaRobotsNofollow}
      robotsIndex={pageProps.data.wpPage.seo.metaRobotsNoindex}
      canonicalUrl={pageProps.data.wpPage.seo.canonical}
      />
      {pageProps.pageContext.schema !== undefined ? 
        <SchemaLinksToAbsolute schemaRaw={pageProps.pageContext.schema} /> 
      : "" }
      <ContainsCustomColours id="body" className="blog-template-default single single-blog blog-single" customColour1={pageProps.pageContext.color1} customColour2={pageProps.pageContext.color1}>
        <section className="bg-triangle is_inview">
          <div className="bg"></div>
          <div className="triangle"></div>
        </section>
        <section className="hero-wrap is_inview">
          <div className="wrapper-large clearfix">
            <div className="image-wrap hero--blog-wrap">
              {pageProps?.pageContext?.featuredImage?.customFeaturedImage?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
              <Img fluid={pageProps?.pageContext?.featuredImage?.customFeaturedImage?.localFile?.childImageSharp?.fluid} alt={pageProps?.pageContext?.featuredImage?.customFeaturedImage?.altText} className="bg-cover BG bgStretch" />
            </div>
            <div className="hero-text-wrap">
              <h1>{pageProps.pageContext.title}</h1>
              <div className="excerpt"></div>
            </div>
          </div>
        </section>


        {components.map((component, index) => {

      if (component.name == 'BasicTextBlock') {
        return <BasicTextBlock {...component.data} key={index} />
      }
    
          return <div>Error: The component {component.name} was not found</div>
        })}

      </ContainsCustomColours>
    </div>
  )
}

export default PageTemplate


    export const query = graphql`
      query PageQuery854($id: String!) {
        wpPage(id: {eq: $id}) {
          title
          seo{
            title
            metaDesc
            metaRobotsNofollow
            metaRobotsNoindex
            canonical
          }
          
    acf_pageBuilder {
      pageBuilder {
        __typename
         
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicAccordion {
         
        accordion {
            heading
            text
        }
        sectionTitle {
            headingType
            titleText
        }
        removeBottomMargin
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicBigBoldStatementBlockQuote {
        
   
            fieldGroupName
            removeBottomMargin
            statement
            textColour
   
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicBrandLogos {
        
      
        label
        removeBottomMargin
        removeGrayscale
        brandLogosGatsby {
          logo {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicCallToAction {
        
      
        fieldGroupName
        removeBottomMargin
        callToActionGatsby {
          ctaButtons {
            buttonText
            pageLink {
              ... on WpPage {
                id
                link
              }
            }
          }
          ctaHeading
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicFullWidthImage {
        
 
        caption
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        notFullWidth
        removeBottomMargin
 
        
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicFullWidthVideo {
        
      
        removeBottomMargin
        videoEmbedCode
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicGallery {
        
      
        fieldGroupName
        removeBottomMargin
        gallery {
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
              relativePath
            }
          }
        }
       
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicImageGrid {
        
 
       imageGrid{
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
       }
        removeBottomMargin
 
        
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicMoreServices {
        
      
        removeBottomMargin
        pickServicesGatsby {
          service1 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service2 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service3 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service4 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service5 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicRelatedPosts {
        
      
        removeBottomMargin
        postPicker {
          buttonText
          headline
          label
          rolloverText
          pickPost {
            ... on WpBlog {
              id
              link
              title
              acf_customFeaturedImage {
                customFeaturedImage {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        }
                    }
                  }
                }
              }
            }
            ... on WpCaseStudy {
              id
              link
              title
              acf_caseStudyFields {
                backgroundImage {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        }
                    }
                  }
                }
              }
            }
          }
          rolloverText
        }
        text
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicTable {
        
      
        fieldGroupName
        removeBottomMargin
        tableRow {
          isHeaderRow
          highlightRow
          tableColumns {
            tableCell
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicTestimonial {
        
      
        fieldGroupName
        personPositionCompany
        personPhoto {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        personPhotoOffsets {
          offsetX
          offsetY
        }
        quotation
        removeBottomMargin
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicTestimonialSlider {
        
        fieldGroupName
        testimonialSlider{
          personPositionCompany
          personPhoto {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
          personPhotoOffsets {
            offsetX
            offsetY
          }
          quotation
        }
        removeBottomMargin
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicTextBlock {
        
      
        addDividerLine
        removeBottomMargin
        text

    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicTextBlock2col {
        
      
        addDividerLine
        removeBottomMargin
        text
        text2
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyAnimatedStatisticArrow {
        
      
      removeBottomMargin
      animatedStatisticWArrow {
        fieldGroupName
        label
        number
        symbol
      }
    
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyAnimatedStatistics6BackgroundImage {
        
        
          fieldGroupName
          heading
          backgroundImage {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
          removeBottomMargin
          text
          statistics {
            addColourBackground
            fieldGroupName
            label
            number
            symbol
          }
        
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyHeroImageBlock {
        
      
        backgroundImageFocalPoint
        backgroundImageOpacity
        backgroundImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        heading
        logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        logoWidth
        removeBottomMargin
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyNumberedSlider {
        
      
        removeBottomMargin
        slider {
          imageType
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
          text
        }
        sliderLabel
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyParallaxImageText {
        
      
        backgroundColour1
        backgroundColour2
        removeBottomMargin
        text
        parallaxImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyQuotation {
        
      
        labelHeading
        personPhoto {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        quotationCompany
        quotationCompanyLink
        quotationPerson
        quotationPosition
        personPositionCompany
        quotation
        removeBottomMargin
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyQuotationSlider {
        
      quotations {
        labelHeading
        personPhoto {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        quotationCompany
        quotationCompanyLink
        quotationPerson
        quotationPosition
        personPositionCompany
        quotation
      }
      removeBottomMargin
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyTextStatBackgroundImage {
        
      
        removeBottomMargin
        backgroundImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        text
        statisticGatsby {
          label
          number
          symbol
        }
    
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceBarChartTextButton {
        
      
        fieldGroupName
        removeBottomMargin
        barChart {
          chartTitle
          dataValues {
            dataValue
            xDataValue
          }
          tooltipXLabelOptions
          tooltipXUnitOptions
          tooltipYLabelOptions
          tooltipYUnitOptions
          xLabel
          xUnitLabel
          yLabel
          yUnitLabel
        }
        barChart2datasets {
          chartTitle
          tooltipXLabelOptions
          tooltipXUnitOptions
          tooltipYLabelOptions
          tooltipYUnitOptions
          xLabel
          xUnitLabel
          yLabel
          yUnitLabel
          dataSets {
            dataSetColour
            dataSetTitle
            dataValues {
              dataValue
              xDataValue
            }
          }
        }
        singleOrMultipleDatasets
        text
        button {
          buttonColour
          buttonLink {
            ... on WpPage {
              id
              link
            }
          }
          buttonText
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceCallToActionWImage {
        
      
        backgroundColor1
        backgroundColor2
        heading
        headingTag
        removeBottomMargin
        text
        buttonGatsby {
          buttonColour
          buttonLink {
            ... on WpPage {
              link
              title
            }
          }
          buttonText
        }
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceIntroSection {
        
        addCountdownTimer
        countdownFrom
        heading
        removeBottomMargin
        scrollingBackgroundText
        agencyPrefixText
        source
        text
        ctaButton{
            target
              title
              url
        }
        animatedIconVideoUrl
        specifyParentPage {
            ... on WpService {
                uri
                title
                acf_serviceFields {
                    abbreviatedTitle
                    longTitle
                }
            }
        }
        specifyGrandparentPage {
            ... on WpService {
                uri
                title
                acf_serviceFields {
                    abbreviatedTitle
                    longTitle
                }
            }
        }
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceLineChartTextButton {
        
      
        removeBottomMargin
        lineChart {
          dataSets {
            dataSetColour
            dataSetTitle
            dataValues {
              dataValue
              xDataValue
            }
          }
          chartTitle
          tooltipXLabelOptions
          tooltipXUnitOptions
          tooltipYLabelOptions
          tooltipYUnitOptions
          xLabel
          xLabelCustomTooltip
          xUnitLabel
          xUnitLabelCustomTooltip
          yLabelCustomTooltip
          yLabel
          yUnitLabel
          yUnitLabelCustomTooltip
        }
        button {
          buttonColour
          buttonLink {
            ... on WpPage {
              link
            }
          }
          buttonText
        }
        text
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceTextImage {
        
        useCustomBackgroundColour
        backgroundColour1
        backgroundColour2
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        imageFocalPoint
        imageToFillSpace
        label
        padding {
          paddingBottom
          paddingLeft
          paddingRight
          paddingTop
        }
        removeBottomMargin
        text
        textimagebutton{
          textimagebuttontext
          textimagebuttonlink
        }
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceTextVideo {
        
      
        backgroundColour1
        backgroundColour2
        imageToFillSpace
        video
        removeBottomMargin
        text
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_Services3Image {
        
      
        removeBottomMargin
        imagesX3 {
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServicesServicesGridVideoTitleText {
        
      
        removeBottomMargin
        servicesServicesGridVideoTitleText {
          videoUrl
          text
          title
          linkToService {
            ... on WpService {
              uri
            }
          }
        }
        servicesGridHeading
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServicesStaffBlock {
        
      
        servicesStaffBlockHeading
        servicesStaffBlockIntroText
        servicesStaffRepeater{
            text
            staffMember{
                ... on WpTeamMember{
                    title
                    slug
                    acf_staff_member{
                        colour
                        image{
                            altText
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1920) {
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_VideoTranscriptionBlock {
        


        text

    
      }
    
      }
    }
  
        }
      }
    `
  